import {
	createRouter,
	createWebHistory
} from "vue-router";
import Home from "../views/Home.vue";

const routes = [{
	path: '/',
	redirect: '/dashboard'
}, {
	path: "/",
	name: "Home",
	component: Home,
	children: [{
			path: "/dashboard",
			name: "dashboard",
			meta: {
				title: '系统首页'
			},
			component: () => import(
				/* webpackChunkName: "dashboard" */
				"../views/Dashboard.vue")
		}, {
			path: "/addReturns",
			name: "addReturns",
			meta: {
				title: '充值'
			},
			component: () => import(
				/* webpackChunkName: "dashboard" */
				"../views/addReturns.vue")
		},
		{
			path: "/customer",
			name: "customer",
			meta: {
				title: '我的客户'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/customer.vue")
		},
		{
			path: "/CRMHigh",
			name: "CRMHigh",
			meta: {
				title: '公海客户'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/CRMHigh.vue")
		},

		{
			path: "/clue",
			name: "baseTable",
			meta: {
				title: '线索列表'
				// title: '客户列表'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/clue.vue")
		},
		{
			path: "/cluePool",
			name: "cluePool",
			meta: {
				title: '线索池'
				// title: '客户列表'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/cluePool.vue")
		},
		{
			path: "/assignLeads",
			name: "assignLeads",
			meta: {
				title: '线索回收'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/assignLeads.vue")
		},

		{
			path: "/record",
			name: "record",
			meta: {
				title: '通话记录'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/record.vue")
		},
		{
			path: "/recording",
			name: "recording",
			meta: {
				title: '录音检测'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/recording.vue")
		},
		{
			path: "/tag",
			name: "tag",
			meta: {
				title: '标签管理',
			},
			component: () => import(
				"../views/tag.vue")
		},
		{
			path: "/customTag",
			name: "customTag",
			meta: {
				title: '字段配置',
			},
			component: () => import(
				"../views/customTag.vue")
		},
		{
			path: "/source",
			name: "source",
			meta: {
				title: '来源配置',
			},
			component: () => import(
				"../views/source.vue")
		}, {
			path: "/release",
			name: "release",
			meta: {
				title: '释放管理',
			},
			component: () => import(
				"../views/release.vue")
		},
		{
			path: "/followProgress",
			name: "followProgress",
			meta: {
				title: '跟进阶段配置',
			},
			component: () => import(
				"../views/followProgress.vue")
		},
		{
			path: "/company",
			name: "company",
			meta: {
				title: '企业列表',
				role: ['admin', 'agent']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/company.vue")
		},
		{
			path: "/department",
			name: "department",
			meta: {
				title: '部门管理'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/department.vue")
		}, {
			path: "/user",
			name: "user",
			meta: {
				title: '员工管理'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/user.vue")
		}, {
			path: "/report",
			name: "report",
			meta: {
				title: '员工统计报表',
				permission: true,
				role: ['admin', 'agent', 'company', 'department']
			},
			component: () => import(
				/* webpackChunkName: "form" */
				"../views/report.vue")
		}, {
			path: "/companyStatistics",
			name: "companyStatistics",
			meta: {
				title: '公司统计报表',
				permission: true,
				role: ['admin', 'agent', 'company', 'department']
			},
			component: () => import(
				/* webpackChunkName: "form" */
				"../views/companyStatistics.vue")
		}, {
			path: "/agent",
			name: "agent",
			meta: {
				title: '代理管理',
				permission: true,
				role: ['admin', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/agent.vue")
		},
		{
			path: "/blackListDatabase",
			name: "blackListDatabase",
			meta: {
				title: '黑名单库',
				permission: true,
				role: ['admin', 'agent', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/blackListDatabase.vue")
		},
		{
			path: "/cardType",
			name: "cardType",
			meta: {
				title: '品牌管理',
				permission: true,
				role: ['admin', 'agent', 'oem'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/cardType.vue")
		},
		{
			path: "/numberPool",
			name: "numberPool",
			meta: {
				title: '号码池',
				permission: true,
				role: ['admin', 'oem'],
				/* , 'agent' */
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/numberPool.vue")
		}, {
			path: "/woniunumberPool",
			name: "woniunumberPool",
			meta: {
				title: '蜗牛号码池',
				permission: true,
				role: ['admin', 'oem'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/woniunumberPool.vue")
		},
		{
			path: "/woniuRecharge",
			name: "woniuRecharge",
			meta: {
				title: '充值列表',
				permission: true,
				role: ['admin'],
				/* , 'agent' */
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/woniuRecharge.vue")
		},
		{
			path: "/woniuBill",
			name: "woniuBill",
			meta: {
				title: '蜗牛话单',
				permission: true,
				role: ['admin', 'oem'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/woniuBill.vue")
		},
		{
			path: "/outboundCallRecords",
			name: "outboundCallRecords",
			meta: {
				title: '外呼记录',
				permission: true,
				role: ['admin', 'agent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/outboundCallRecords.vue")
		}, {
			path: "/shutdownRecord",
			name: "shutdownRecord",
			meta: {
				title: '蜗牛停机记录',
				permission: true,
				role: ['admin', 'oem'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/shutdownRecord.vue")
		}, {
			path: "/restartRecordList",
			name: "restartRecordList",
			meta: {
				title: '停复机',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/restartRecordList.vue")
		}, {
			path: "/userInfo",
			name: "userInfo",
			meta: {
				title: '用户查询',
				permission: true,
				role: ['admin', 'agent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/userInfo.vue")
		}, {
			path: "/djRecharge",
			name: "djRecharge",
			meta: {
				title: '迪加充值',
				permission: true,
				role: ['admin'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/djRecharge.vue")
		}, {
			path: "/activationList",
			name: "activationList",
			meta: {
				title: '限制返档',
				permission: true,
				role: ['admin', 'agent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/activationList.vue")
		}, {
			path: "/DJAbnormalCallList",
			name: "DJAbnormalCallList",
			meta: {
				title: '迪加异常话单',
				permission: true,
				role: ['admin', 'agent'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/DJAbnormalCallList.vue")
		},
		{
			path: "/accountReview",
			name: "accountReview",
			meta: {
				title: '开户审核查询'
			},
			component: () => import(
				/* webpackChunkName: "dashboard" */
				"../views/accountReview.vue")
		},
		{
			path: "/workOrder",
			name: "workOrder",
			meta: {
				title: '工单管理',
				permission: true,
				role: ['admin', 'oem'],
				visiable: true,
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/workOrder.vue")
		}, {
			path: "/agentSerial",
			name: "agentSerial",
			meta: {
				title: '代理商分配记录',
				permission: true,
				role: ['admin', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/agentSerial.vue")
		}, {
			path: "/userSerial",
			name: "userSerial",
			meta: {
				title: '员工分配记录',
				permission: true,
				role: ['admin', 'agent', 'oem'],

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/userSerial.vue")
		}, {
			path: "/flowManagement",
			name: "flowManagement",
			meta: {
				title: '流水管理',
				permission: true,
				role: ['admin', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/flowManagement.vue")
		},
		{
			path: "/stop",
			name: "stop",
			meta: {
				title: '号码停机',
				permission: true,
				role: ['admin', 'agent', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/stopPhone.vue")
		}, {
			path: "/validate",
			name: "validate",
			meta: {
				title: '号码验证'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/validate.vue")
		},
		{
			path: "/blackList",
			name: "blackList",
			meta: {
				title: '黑名单'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/blackList.vue")
		},
		{
			path: "/userManual",
			name: "userManual",
			meta: {
				title: '用户手册'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/userManual.vue")
		},
		{
			path: "/swipes",
			name: "swipes",
			meta: {
				title: 'APP轮播图'
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/swipes.vue")
		},
		{
			path: "/afterSales",
			name: "afterSales",
			meta: {
				title: '售后信息',
				role: ['admin', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/afterSales.vue")
		},
		{
			path: "/role",
			name: "role",
			meta: {
				title: '角色管理',
				permission: true,
				role: ['admin', 'agent', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/role.vue")
		}, {
			path: "/notice",
			name: "notice",
			meta: {
				title: '公告',
				permission: true,
				role: ['admin', 'agent', ]
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/notice.vue")
		},
		{
			path: "/addNotice",
			name: "addNotice",
			meta: {
				title: '发布公告',
				permission: true,
				role: ['admin', 'agent', ]
			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/addNotice.vue")
		},
		{
			path: '/404',
			name: '404',
			meta: {
				title: '找不到页面'
			},
			component: () => import( /* webpackChunkName: "404" */
				'../views/404.vue')
		}, {
			path: '/403',
			name: '403',
			meta: {
				title: '没有权限'
			},
			component: () => import( /* webpackChunkName: "403" */
				'../views/403.vue')
		},
		{
			path: "/operationManual",
			name: "operationManual",
			meta: {
				title: '使用手册'
			},
			component: () => import(
				/* webpackChunkName: "dashboard" */
				"../views/operationManual.vue")
		},
		{
			path: "/reportNumber",
			name: "reportNumber",
			meta: {
				title: '报备绿名单号码',
				permission: true,
				role: ['admin', 'oem']
			},
			component: () => import(
				/* webpackChunkName: "dashboard" */
				"../views/reportNumber.vue")
		},
		{
			path: "/operationLog",
			name: "operationLog",
			meta: {
				title: '员工操作日志',
				permission: true,
				role: ['admin', 'agent', 'oem'],

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/operationLog.vue")
		},
		{
			path: "/termOfValidity",
			name: "termOfValidity",
			meta: {
				title: '有效期管理',
				permission: true,
				role: ['admin', 'agent'],

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/termOfValidity.vue")
		},
		{
			path: "/setColor",
			name: "setColor",
			meta: {
				title: '设置主题',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/setColor.vue")
		},
		{
			path: "/packageManagement",
			name: "packageManagement",
			meta: {
				title: '套餐管理',
				permission: true,
				role: ['admin', 'agent', 'company']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/packageManagement.vue")
		},
		{
			path: "/AXBinventory",
			name: "AXBinventory",
			meta: {
				title: 'AXB库存',
				permission: true,
				role: ['admin', 'agent']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/AXBinventory.vue")
		}, {
			path: "/purchaseList",
			name: "purchaseList",
			meta: {
				title: '已购号码',
				permission: true,
				role: ['admin', 'agent', 'company']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/purchaseList.vue")
		}, {
			path: "/consultationDetailed",
			name: "consultationDetailed",
			meta: {
				title: '消费明细',
				permission: true,
				role: ['admin', 'agent', 'company']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/consultationDetailed.vue")
		},
		{
			path: "/seating",
			name: "seating",
			meta: {
				title: '坐席数据',
				permission: true,
				role: ['admin', 'agent']
			},
			component: () => import(
				"../views/seating.vue")
		},
		{
			path: "/userPhone",
			name: "userPhone",
			meta: {
				title: '账号登录记录',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				"../views/userPhone.vue")
		},
		{
			path: "/remoteLogin",
			name: "remoteLogin",
			meta: {
				title: '位置异常',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				"../views/remoteLogin.vue")
		},
		{
			path: "/callsNumber",
			name: "callsNumber",
			meta: {
				title: '电话邦放行次数',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				"../views/callsNumber.vue")
		},
		{
			path: "/gmAbnormalCallList",
			name: "gmAbnormalCallList",
			meta: {
				title: '国美异常话单',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				"../views/gmAbnormalCallList.vue")
		},
		{
			path: "/gmAbnormalNumber",
			name: "gmAbnormalNumber",
			meta: {
				title: '国美异常号码',
				permission: true,
				role: ['admin']
			},
			component: () => import(
				"../views/gmAbnormalNumber.vue")
		},
		{
			path: "/highSeas",
			name: "highSeas",
			meta: {
				title: '公海数据',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal']
			},
			component: () => import(
				"../views/highSeas.vue")
		},
		{
			path: "/advancedSearch",
			name: "advancedSearch",
			meta: {
				title: '找线索',
				permission: true,
				// role: ['admin'],
				role: ['admin', 'agent', 'company', 'department', 'normal']
			},
			component: () => import(
				"../views/advancedSearch.vue")
		}, {
			path: "/companySearch",
			name: "companySearch",
			meta: {
				title: '找企业',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal']
			},
			component: () => import(
				"../views/companySearch.vue")
		}, {
			path: "/shopSearch",
			name: "shopSearch",
			meta: {
				title: '找店铺',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal']
			},
			component: () => import(
				"../views/shopSearch.vue")
		}, {
			path: "/industryTemplate",
			name: "industryTemplate",
			meta: {
				title: '找行业',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal']
			},
			component: () => import(
				"../views/industryTemplate.vue")
		}, {
			path: "/mapSearch",
			name: "mapSearch",
			meta: {
				title: '地图找客',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal']
			},
			component: () => import(
				"../views/mapSearch.vue")
		}, {
			path: "/batchCheckCompany",
			name: "batchCheckCompany",
			meta: {
				title: '批量查询',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal']
			},
			component: () => import(
				"../views/batchCheckCompany.vue")
		}, {
			path: "/poolAccount",
			name: "poolAccount",
			meta: {
				title: '寻迹通账号管理',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal']
			},
			component: () => import(
				"../views/poolAccount.vue")
		},
		{
			path: "/guide",
			name: "guide",
			meta: {
				title: '指引',
				permission: true,
				role: ['admin', 'agent', 'company', 'department', 'normal']
			},
			component: () => import(
				"../views/guide.vue")
		},
		{
			path: "/GM",
			name: "GM",
			meta: {
				title: '国美录音',
				permission: true,
				role: ['gm']
			},
			component: () => import(
				"../views/GM.vue")
		},
		{
			path: "/OEMset",
			name: "OEMset",
			meta: {
				title: 'OEM设置',
				permission: true,
				role: ['admin', 'agent']
			},
			component: () => import(
				/* webpackChunkName: "form" */
				"../views/OEMset.vue")
		},
		{
			path: "/OEMswiper",
			name: "OEMswiper",
			meta: {
				title: '登录轮播图',
				permission: true,
				role: ['admin', 'agent']
			},
			component: () => import(
				/* webpackChunkName: "form" */
				"../views/OEMswiper.vue")
		},
		{
			path: "/prestore",
			name: "prestore",
			meta: {
				title: '蜗牛预存款',
				permission: true,
				role: ['admin']

			},
			component: () => import(
				/* webpackChunkName: "table" */
				"../views/prestore.vue")
		},
	]
}, {
	path: "/login",
	name: "Login",
	meta: {
		title: '登录',
		loginRequest: true
	},
	component: () => import(
		/* webpackChunkName: "login" */
		"../views/Login.vue")
}];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes
});

router.beforeEach((to, from, next) => {

	document.title = `${to.meta.title} | ${localStorage.getItem('webName')}`;
	const role = localStorage.getItem('ms_role');
	if (!role && to.path !== '/login') {
		next('/login');
	} else if (to.meta.permission) {
		// 如果是管理员权限则可进入，这里只是简单的模拟管理员权限而已

		to.meta.role.indexOf(role) >= 0 ? next() : next('/403');
	} else {
		next();
	}
});
// router.afterEach(function(to,form){
//
// })

export default router;
