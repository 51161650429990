import axios from 'axios';
import router from "../router";

const service = axios.create({
	// process.env.NODE_ENV === 'development' 来判断是否开发环境
	// easy-mock服务挂了，暂时不使用了
	// baseURL: 'https://localhost:8080/api/',
	// headers: {'Authorization': localStorage.getItem('token')},
	timeout: 500000
});

service.interceptors.request.use(
	config => {
		config.headers.Accept = "application/json, text/plain, */*";
		config.headers.Authorization = localStorage.getItem('token');
		config.headers.type = 'webapp';
		return config;
	},
	error => {
		return Promise.reject();
	}
);

service.interceptors.response.use(
	response => {
		if (response.headers.expiringsoon == 'true') {
			axios({
					url: '/api/login/refreshToken',
					method: 'post',
					headers: {
						'Accept': 'application/json, text/plain, */*',
						"Authorization": localStorage.getItem('token')
					}
				})
				.then(function(res) {
					localStorage.setItem('token', 'Bearer ' + res.data.data);
				})
				.catch(function(error) {

				});

		}

		if (response.status === 200) {

			return response.data;
		} else {
			Promise.reject();
		}
	},
	error => {
		// 登录过期
		if (error.response.data.status === 401) {
			localStorage.removeItem('token');
			router.replace("/login");
			setTimeout(() => {
				window.location.reload();
			}, 1000);
		}
		// if (error.response.data.status === 403) {
		// 	localStorage.removeItem('token');
		// 	router.replace("/login");
		// }
		return Promise.reject();
	}
);

export default service;
