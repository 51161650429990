import request from '../utils/request';
import qs from "qs";
// eslint-disable-next-line no-unused-vars
export const fetchCompany = currentUserId => {
	return request.get('/api/company/all?currentUserId=' + currentUserId)
};

// eslint-disable-next-line no-unused-vars
export const fetchDepartment = data => {
	return request.get('/api/department/all?companyId=' + data.companyId + '&&currentUserId=' + data.currentUserId)
};

// eslint-disable-next-line no-unused-vars
export const fetchUsers = userId => {
	return request.get('/api/user/users')
};

export const fetchUsersByCompanyId = companyId => {
	return request.get('/api/user/fetchUsersByCompanyId?companyId=' + companyId)
};

export const fetchUsersByDepartmentId = data => {
	return request.get('/api/user/fetchUsersByDepartmentId?departmentId=' + data.departmentId + '&&currentUserId=' +
		data.currentUserId)
};

// eslint-disable-next-line no-unused-vars
export const fetchDefaultUsers = currentUserId => {
	return request.get('/api/user/fetchDefaultUsers?currentUserId=' + currentUserId)
};

// 获取登录的网址
export const getOemInfo = url => {
	return request.get('/api/oem/getOemInfo?url=' + url)
};


export const getOperationRecord = param => {
	return request.post('/api/user/getOperationRecord', qs.stringify(param))
};
export const refreshToken = param => {
	return request.post('/api/login/refreshToken', qs.stringify(param))
};
export const getloginRecord = param => {
	return request.post('/api/login/loginRecord', qs.stringify(param))
};
export const queryRequest = param => {
	return request.post('/api/record/queryRequest', qs.stringify(param))
};
export const addressErrList = param => {
	return request.post('/api/user/addressErrList', qs.stringify(param))
};
