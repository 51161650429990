<template>
	<div class="sidebar">
		<div class="logo-img">
			<div class="logo">
				<img class="img" :src="webInfo.topSmallPicture" alt="" />
				<img v-if="!collapse" class="text" :src="webInfo.topBigPicture" alt="" />
			</div>
		</div>
		<el-menu class="sidebar-el-menu" :default-active="onRoutes" :collapse="collapse" unique-opened router v-if="role == 'admin'">
			<el-menu-item index="dashboard" :class="onRoutes == 'dashboard' ? theme3 : ''">
				<i class="el-icon-lx-home"></i>
				<span>系统首页</span>
			</el-menu-item>
			<el-submenu index="advancedSearch">
				<template v-slot:title="title">
					<i
						class="el-icon-folder-opened"
						:class="[
							onRoutes == 'advancedSearch' ? theme3 : '',
							onRoutes == 'companySearch' ? theme3 : '',
							onRoutes == 'shopSearch' ? theme3 : '',
							onRoutes == 'industryTemplate' ? theme3 : '',
							onRoutes == 'mapSearch' ? theme3 : '',
							onRoutes == 'batchCheckCompany' ? theme3 : '',
							onRoutes == 'poolAccount' ? theme3 : '',
							onRoutes == 'highSeas' ? theme3 : ''
						]"
					></i>
					<span
						:class="[
							onRoutes == 'advancedSearch' ? theme3 : '',
							onRoutes == 'companySearch' ? theme3 : '',
							onRoutes == 'shopSearch' ? theme3 : '',
							onRoutes == 'industryTemplate' ? theme3 : '',
							onRoutes == 'mapSearch' ? theme3 : '',
							onRoutes == 'batchCheckCompany' ? theme3 : '',
							onRoutes == 'poolAccount' ? theme3 : '',
							onRoutes == 'highSeas' ? theme3 : ''
						]"
					>
						寻迹通
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="advancedSearch" :class="onRoutes == 'advancedSearch' ? theme6 : ''">找线索</el-menu-item>
					<el-menu-item index="companySearch" :class="onRoutes == 'companySearch' ? theme6 : ''">找企业</el-menu-item>
					<el-menu-item index="shopSearch" :class="onRoutes == 'shopSearch' ? theme6 : ''">找店铺</el-menu-item>
					<el-menu-item index="industryTemplate" :class="onRoutes == 'industryTemplate' ? theme6 : ''">找行业</el-menu-item>
					<el-menu-item index="mapSearch" :class="onRoutes == 'mapSearch' ? theme6 : ''">地图找客</el-menu-item>
					<el-menu-item index="batchCheckCompany" :class="onRoutes == 'batchCheckCompany' ? theme6 : ''">批量查询</el-menu-item>
					<el-menu-item index="poolAccount" :class="onRoutes == 'poolAccount' ? theme6 : ''">账号管理</el-menu-item>
					<el-menu-item-group><el-menu-item index="highSeas" :class="onRoutes == 'highSeas' ? theme6 : ''">数据池</el-menu-item></el-menu-item-group>
				</el-menu-item-group>
			</el-submenu>

			<el-submenu index="packageManagement">
				<template v-slot:title="title">
					<i
						class="el-icon-guide"
						:class="[
							onRoutes == 'packageManagement' ? theme3 : '',
							onRoutes == 'AXBinventory' ? theme3 : '',
							onRoutes == 'purchaseList' ? theme3 : '',
							onRoutes == 'consultationDetailed' ? theme3 : ''
						]"
					></i>
					<span
						:class="[
							onRoutes == 'packageManagement' ? theme3 : '',
							onRoutes == 'AXBinventory' ? theme3 : '',
							onRoutes == 'purchaseList' ? theme3 : '',
							onRoutes == 'consultationDetailed' ? theme3 : ''
						]"
					>
						AXB
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="packageManagement" :class="onRoutes == 'packageManagement' ? theme6 : ''">套餐管理</el-menu-item>
					<el-menu-item index="AXBinventory" :class="onRoutes == 'AXBinventory' ? theme6 : ''">AXB库存</el-menu-item>
					<el-menu-item index="purchaseList" :class="onRoutes == 'purchaseList' ? theme6 : ''">已购号码</el-menu-item>
					<el-menu-item index="consultationDetailed" :class="onRoutes == 'consultationDetailed' ? theme6 : ''">消费明细</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="customer">
				<template v-slot:title="title">
					<i
						class="el-icon-user"
						:class="[
							onRoutes == 'customer' ? theme3 : '',
							onRoutes == 'CRMHigh' ? theme3 : '',
							onRoutes == 'customTag' ? theme3 : '',
							onRoutes == 'followProgress' ? theme3 : '',
							onRoutes == 'source' ? theme3 : '',
							onRoutes == 'release' ? theme3 : '',
							onRoutes == 'tag' ? theme3 : ''
						]"
					></i>
					<span
						:class="[
							onRoutes == 'customer' ? theme3 : '',
							onRoutes == 'CRMHigh' ? theme3 : '',
							onRoutes == 'customTag' ? theme3 : '',
							onRoutes == 'followProgress' ? theme3 : '',
							onRoutes == 'source' ? theme3 : '',
							onRoutes == 'release' ? theme3 : '',
							onRoutes == 'tag' ? theme3 : ''
						]"
					>
						CRM管理
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="customer" :class="onRoutes == 'customer' ? theme6 : ''">我的客户</el-menu-item>
					<el-menu-item index="CRMHigh" :class="onRoutes == 'CRMHigh' ? theme6 : ''">公海客户</el-menu-item>
					<el-menu-item index="customTag" :class="onRoutes == 'customTag' ? theme6 : ''">字段配置</el-menu-item>
					<el-menu-item index="followProgress" :class="onRoutes == 'followProgress' ? theme6 : ''">跟进阶段配置</el-menu-item>
					<el-menu-item index="source" :class="onRoutes == 'source' ? theme6 : ''">来源配置</el-menu-item>
					<el-menu-item index="release" :class="onRoutes == 'release' ? theme6 : ''">释放管理</el-menu-item>
					<el-menu-item index="tag" :class="onRoutes == 'tag' ? theme6 : ''">标签管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="clue">
				<template v-slot:title="title">
					<i class="el-icon-data-line" :class="[onRoutes == 'clue' ? theme3 : '', onRoutes == 'assignLeads' ? theme3 : '', onRoutes == 'cluePool' ? theme3 : '']"></i>
					<span :class="[onRoutes == 'clue' ? theme3 : '', onRoutes == 'assignLeads' ? theme3 : '', onRoutes == 'cluePool' ? theme3 : '']">线索管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="clue" :class="onRoutes == 'clue' ? theme6 : ''">线索列表</el-menu-item>
					<el-menu-item index="assignLeads" :class="onRoutes == 'assignLeads' ? theme6 : ''">线索回收</el-menu-item>
					<el-menu-item index="cluePool" :class="onRoutes == 'cluePool' ? theme6 : ''">线索池</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="company">
				<template v-slot:title="title">
					<i class="el-icon-lx-cascades" :class="[onRoutes == 'company' ? theme3 : '', onRoutes == 'department' ? theme3 : '', onRoutes == 'user' ? theme3 : '']"></i>
					<span :class="[onRoutes == 'company' ? theme3 : '', onRoutes == 'department' ? theme3 : '', onRoutes == 'user' ? theme3 : '']">企业管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="company" :class="onRoutes == 'company' ? theme6 : ''">企业列表</el-menu-item>
					<el-menu-item index="department" :class="onRoutes == 'department' ? theme6 : ''">部门管理</el-menu-item>
					<el-menu-item index="user" :class="onRoutes == 'user' ? theme6 : ''">员工管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>

			<el-submenu index="record">
				<template v-slot:title="title">
					<i class="el-icon-phone-outline" :class="[onRoutes == 'record' ? theme3 : '', onRoutes == 'recording' ? theme3 : '']"></i>
					<span :class="[onRoutes == 'record' ? theme3 : '', onRoutes == 'recording' ? theme3 : '']">话务管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="record" :class="onRoutes == 'record' ? theme6 : ''">通话记录</el-menu-item>
					<el-menu-item index="recording" :class="onRoutes == 'recording' ? theme6 : ''">录音检测</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="userInfo" v-if="webName == '云企通'">
				<template v-slot:title="title">
					<img style="width: 18px;margin-right: 10px;" src="../assets/img/dijia.png" alt="" />
					<span
						:class="[
							onRoutes == 'userInfo' ? theme3 : '',
							onRoutes == 'restartRecordList' ? theme3 : '',
							onRoutes == 'activationList' ? theme3 : '',
							onRoutes == 'djRecharge' ? theme3 : '',
							onRoutes == 'DJAbnormalCallList' ? theme3 : '',
							onRoutes == 'accountReview' ? theme3 : ''
						]"
					>
						迪加管理
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="userInfo" :class="onRoutes == 'userInfo' ? theme6 : ''">用户查询</el-menu-item>
					<el-menu-item index="restartRecordList" :class="onRoutes == 'restartRecordList' ? theme6 : ''">停复机</el-menu-item>
					<el-menu-item index="activationList" :class="onRoutes == 'activationList' ? theme6 : ''">限制返档</el-menu-item>
					<el-menu-item index="djRecharge" :class="onRoutes == 'djRecharge' ? theme6 : ''">迪加充值</el-menu-item>
					<el-menu-item index="DJAbnormalCallList" :class="onRoutes == 'DJAbnormalCallList' ? theme6 : ''">迪加异常话单</el-menu-item>
					<el-menu-item index="accountReview" v-if="" :class="onRoutes == 'accountReview' ? theme6 : ''">开户审核查询</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-menu-item index="woniuRecharge" :class="onRoutes == 'woniuRecharge' ? theme3 : ''">
				<i class="el-icon-time"></i>
				<span>充值列表</span>
			</el-menu-item>
			<el-submenu index="report">
				<template v-slot:title="title">
					<i class="el-icon-document-copy" :class="[onRoutes == 'report' ? theme3 : '', onRoutes == 'companyStatistics' ? theme3 : '']"></i>
					<span :class="[onRoutes == 'report' ? theme3 : '', onRoutes == 'companyStatistics' ? theme3 : '']">统计管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="report" :class="onRoutes == 'report' ? theme6 : ''">员工统计报表</el-menu-item>
					<el-menu-item index="companyStatistics" :class="onRoutes == 'companyStatistics' ? theme6 : ''">公司统计报表</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="reportNumber">
				<template v-slot:title="title">
					<i
						class="el-icon-phone"
						:class="[
							onRoutes == 'woniunumberPool' ? theme3 : '',
							onRoutes == 'woniuBill' ? theme3 : '',
							onRoutes == 'shutdownRecord' ? theme3 : '',
							onRoutes == 'gmAbnormalCallList' ? theme3 : '',
							onRoutes == 'gmAbnormalNumber' ? theme3 : '',
							onRoutes == 'workOrder' ? theme3 : '',
							onRoutes == 'prestore' ? theme3 : '',
							onRoutes == 'reportNumber' ? theme3 : ''
						]"
					></i>
					<span
						:class="[
							onRoutes == 'woniunumberPool' ? theme3 : '',
							onRoutes == 'woniuBill' ? theme3 : '',
							onRoutes == 'shutdownRecord' ? theme3 : '',
							onRoutes == 'gmAbnormalCallList' ? theme3 : '',
							onRoutes == 'gmAbnormalNumber' ? theme3 : '',
							onRoutes == 'workOrder' ? theme3 : '',
							onRoutes == 'prestore' ? theme3 : '',
							onRoutes == 'reportNumber' ? theme3 : ''
						]"
					>
						号码分类
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="woniunumberPool" :class="onRoutes == 'woniunumberPool' ? theme6 : ''">蜗牛号码池</el-menu-item>
					<el-menu-item index="woniuBill" :class="onRoutes == 'woniuBill' ? theme6 : ''">蜗牛话单</el-menu-item>
					<el-menu-item index="prestore" :class="onRoutes == 'prestore' ? theme6 : ''">蜗牛预存款</el-menu-item>
					<el-menu-item index="shutdownRecord" :class="onRoutes == 'shutdownRecord' ? theme6 : ''">蜗牛停机记录</el-menu-item>
					<el-menu-item index="gmAbnormalCallList" :class="onRoutes == 'gmAbnormalCallList' ? theme6 : ''">国美异常话单</el-menu-item>
					<el-menu-item index="gmAbnormalNumber" :class="onRoutes == 'gmAbnormalNumber' ? theme6 : ''">国美异常号码</el-menu-item>
					<el-menu-item index="workOrder" :class="onRoutes == 'workOrder' ? theme6 : ''">工单管理</el-menu-item>
					<el-menu-item index="reportNumber" :class="onRoutes == 'reportNumber' ? theme6 : ''">报备绿名单号码</el-menu-item>
				</el-menu-item-group>
			</el-submenu>

			<el-menu-item index="blackList" :class="onRoutes == 'blackList' ? theme3 : ''">
				<i class="el-icon-s-release"></i>
				<span>黑名单</span>
			</el-menu-item>
			<el-menu-item index="validate" :class="onRoutes == 'validate' ? theme3 : ''">
				<i class="el-icon-finished"></i>
				<span>号码验证</span>
			</el-menu-item>
			<el-submenu index="agent">
				<template v-slot:title="title">
					<i
						class="el-icon-set-up"
						:class="[
							onRoutes == 'agent' ? theme3 : '',
							onRoutes == 'agentSerial' ? theme3 : '',
							onRoutes == 'flowManagement' ? theme3 : '',
							onRoutes == 'seating' ? theme3 : '',
							onRoutes == 'userPhone' ? theme3 : '',
							onRoutes == 'remoteLogin' ? theme3 : '',
							onRoutes == 'callsNumber' ? theme3 : '',
							onRoutes == 'outboundCallRecords' ? theme3 : ''
						]"
					></i>
					<span
						:class="[
							onRoutes == 'agent' ? theme3 : '',
							onRoutes == 'agentSerial' ? theme3 : '',
							onRoutes == 'flowManagement' ? theme3 : '',
							onRoutes == 'seating' ? theme3 : '',
							onRoutes == 'userPhone' ? theme3 : '',
							onRoutes == 'remoteLogin' ? theme3 : '',
							onRoutes == 'callsNumber' ? theme3 : '',
							onRoutes == 'outboundCallRecords' ? theme3 : ''
						]"
					>
						管理员权限
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="agent" :class="onRoutes == 'agent' ? theme6 : ''">代理商管理</el-menu-item>
					<el-menu-item index="agentSerial" :class="onRoutes == 'agentSerial' ? theme6 : ''">代理商分配记录</el-menu-item>
					<el-menu-item index="flowManagement" :class="onRoutes == 'flowManagement' ? theme6 : ''">流水管理</el-menu-item>
					<!-- <el-menu-item index="numberPool" :class="onRoutes == 'woniunumberPool' ? theme6 : ''">号码池</el-menu-item> -->
					<!-- <el-menu-item index="stop" :class="onRoutes == 'woniunumberPool' ? theme6 : ''">号码停机</el-menu-item> -->
					<el-menu-item index="seating" :class="onRoutes == 'seating' ? theme6 : ''">坐席数据</el-menu-item>
					<el-menu-item index="userPhone" :class="onRoutes == 'userPhone' ? theme6 : ''">账号登录记录</el-menu-item>
					<el-menu-item index="remoteLogin" :class="onRoutes == 'remoteLogin' ? theme6 : ''">位置异常</el-menu-item>
					<el-menu-item index="callsNumber" :class="onRoutes == 'callsNumber' ? theme6 : ''">电话邦放行次数</el-menu-item>
					<el-menu-item index="outboundCallRecords" :class="onRoutes == 'outboundCallRecords' ? theme6 : ''">外呼记录</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="cardType">
				<template v-slot:title="title">
					<i
						class="el-icon-s-tools"
						:class="[
							onRoutes == 'cardType' ? theme3 : '',
							onRoutes == 'blackListDatabase' ? theme3 : '',
							onRoutes == 'userSerial' ? theme3 : '',
							onRoutes == 'role' ? theme3 : '',
							onRoutes == 'operationLog' ? theme3 : '',
							onRoutes == 'termOfValidity' ? theme3 : '',
							onRoutes == 'setColor' ? theme3 : ''
						]"
					></i>
					<span
						:class="[
							onRoutes == 'cardType' ? theme3 : '',
							onRoutes == 'blackListDatabase' ? theme3 : '',
							onRoutes == 'userSerial' ? theme3 : '',
							onRoutes == 'role' ? theme3 : '',
							onRoutes == 'operationLog' ? theme3 : '',
							onRoutes == 'termOfValidity' ? theme3 : '',
							onRoutes == 'setColor' ? theme3 : ''
						]"
					>
						系统设置
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="cardType" :class="onRoutes == 'cardType' ? theme6 : ''">品牌管理</el-menu-item>
					<!-- //在领尚注释!-->
					<el-menu-item index="blackListDatabase" :class="onRoutes == 'blackListDatabase' ? theme6 : ''">黑名单库</el-menu-item>
					<el-menu-item index="userSerial" :class="onRoutes == 'userSerial' ? theme6 : ''">员工分配记录</el-menu-item>
					<el-menu-item index="role" :class="onRoutes == 'role' ? theme6 : ''">角色管理</el-menu-item>
					<!-- //在领尚注释!-->
					<el-menu-item index="operationLog" :class="onRoutes == 'operationLog' ? theme6 : ''">员工操作日志</el-menu-item>
					<el-menu-item index="termOfValidity" :class="onRoutes == 'termOfValidity' ? theme6 : ''">有效期管理</el-menu-item>
					<el-menu-item index="setColor" :class="onRoutes == 'setColor' ? theme6 : ''">设置主题</el-menu-item>
				</el-menu-item-group>
			</el-submenu>

			<el-submenu index="notice">
				<template v-slot:title="title">
					<i class="el-icon-chat-dot-round" :class="[onRoutes == 'notice' ? theme3 : '', onRoutes == 'addNotice' ? theme3 : '']"></i>
					<span :class="[onRoutes == 'notice' ? theme3 : '', onRoutes == 'addNotice' ? theme3 : '']">公告管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="notice" :class="onRoutes == 'notice' ? theme6 : ''">公告列表</el-menu-item>
					<el-menu-item index="addNotice" :class="onRoutes == 'addNotice' ? theme6 : ''">发布公告</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="OEMset">
				<template v-slot:title="title">
					<i class="el-icon-document-copy" :class="[onRoutes == 'OEMset' ? theme3 : '', onRoutes == 'OEMswiper' ? theme3 : '', onRoutes == 'swipes' ? theme3 : '']"></i>
					<span :class="[onRoutes == 'OEMset' ? theme3 : '', onRoutes == 'OEMswiper' ? theme3 : '', onRoutes == 'swipes' ? theme3 : '']">OEM管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="OEMset" :class="onRoutes == 'OEMset' ? theme6 : ''">OEM设置</el-menu-item>
					<el-menu-item index="OEMswiper" :class="onRoutes == 'OEMswiper' ? theme6 : ''">登录轮播图</el-menu-item>
					<el-menu-item index="swipes" :class="onRoutes == 'swipes' ? theme6 : ''">APP轮播图</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-menu-item index="afterSales" :class="onRoutes == 'afterSales' ? theme3 : ''">
				<i class="el-icon-warning-outline"></i>
				<span>售后信息</span>
			</el-menu-item>
			<el-menu-item index="operationManual" v-if="webName == '云企通'" :class="onRoutes == 'operationManual' ? theme3 : ''">
				<i class="el-icon-notebook-1"></i>
				<span>使用手册</span>
			</el-menu-item>

			<div class="login_info" v-if="collapse == false">
				<div>最后登录时间:{{ lastLoginAt }}</div>
			</div>
		</el-menu>
		<el-menu
			class="sidebar-el-menu"
			:default-active="onRoutes"
			:collapse="collapse"
			background-color="#ffffff"
			text-color="#000"
			active-text-color="#ffffff"
			unique-opened
			router
			v-else-if="role == 'agent'"
		>
			<el-menu-item index="dashboard" :class="onRoutes == 'dashboard' ? theme3 : ''">
				<i class="el-icon-lx-home"></i>
				<span>系统首页</span>
			</el-menu-item>
			<el-submenu index="advancedSearch">
				<template v-slot:title="title">
					<i
						class="el-icon-folder-opened"
						:class="[
							onRoutes == 'advancedSearch' ? theme3 : '',
							onRoutes == 'companySearch' ? theme3 : '',
							onRoutes == 'shopSearch' ? theme3 : '',
							onRoutes == 'industryTemplate' ? theme3 : '',
							onRoutes == 'mapSearch' ? theme3 : '',
							onRoutes == 'batchCheckCompany' ? theme3 : '',
							onRoutes == 'poolAccount' ? theme3 : '',
							onRoutes == 'highSeas' ? theme3 : ''
						]"
					></i>
					<span
						:class="[
							onRoutes == 'advancedSearch' ? theme3 : '',
							onRoutes == 'companySearch' ? theme3 : '',
							onRoutes == 'shopSearch' ? theme3 : '',
							onRoutes == 'industryTemplate' ? theme3 : '',
							onRoutes == 'mapSearch' ? theme3 : '',
							onRoutes == 'batchCheckCompany' ? theme3 : '',
							onRoutes == 'poolAccount' ? theme3 : '',
							onRoutes == 'highSeas' ? theme3 : ''
						]"
					>
						寻迹通
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="advancedSearch" :class="onRoutes == 'advancedSearch' ? theme6 : ''">找线索</el-menu-item>
					<el-menu-item index="companySearch" :class="onRoutes == 'companySearch' ? theme6 : ''">找企业</el-menu-item>
					<el-menu-item index="shopSearch" :class="onRoutes == 'shopSearch' ? theme6 : ''">找店铺</el-menu-item>
					<el-menu-item index="industryTemplate" :class="onRoutes == 'industryTemplate' ? theme6 : ''">找行业</el-menu-item>
					<el-menu-item index="mapSearch" :class="onRoutes == 'mapSearch' ? theme6 : ''">地图找客</el-menu-item>
					<el-menu-item index="batchCheckCompany" :class="onRoutes == 'batchCheckCompany' ? theme6 : ''">批量查询</el-menu-item>
					<el-menu-item index="poolAccount" :class="onRoutes == 'poolAccount' ? theme6 : ''">账号管理</el-menu-item>
					<el-menu-item-group><el-menu-item index="highSeas" :class="onRoutes == 'highSeas' ? theme6 : ''">数据池</el-menu-item></el-menu-item-group>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="AXBinventory">
				<template v-slot:title="title">
					<i
						class="el-icon-guide"
						:class="[onRoutes == 'AXBinventory' ? theme3 : '', onRoutes == 'purchaseList' ? theme3 : '', onRoutes == 'consultationDetailed' ? theme3 : '']"
					></i>
					<span :class="[onRoutes == 'AXBinventory' ? theme3 : '', onRoutes == 'purchaseList' ? theme3 : '', onRoutes == 'consultationDetailed' ? theme3 : '']">AXB</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="AXBinventory" :class="onRoutes == 'AXBinventory' ? theme6 : ''">AXB库存</el-menu-item>
					<el-menu-item index="purchaseList" :class="onRoutes == 'purchaseList' ? theme6 : ''">已购号码</el-menu-item>
					<el-menu-item index="consultationDetailed" :class="onRoutes == 'consultationDetailed' ? theme6 : ''">消费明细</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="customer">
				<template v-slot:title="title">
					<i
						class="el-icon-user"
						:class="[
							onRoutes == 'customer' ? theme3 : '',
							onRoutes == 'CRMHigh' ? theme3 : '',
							onRoutes == 'customTag' ? theme3 : '',
							onRoutes == 'followProgress' ? theme3 : '',
							onRoutes == 'source' ? theme3 : '',
							onRoutes == 'release' ? theme3 : '',
							onRoutes == 'tag' ? theme3 : ''
						]"
					></i>
					<span
						:class="[
							onRoutes == 'customer' ? theme3 : '',
							onRoutes == 'CRMHigh' ? theme3 : '',
							onRoutes == 'customTag' ? theme3 : '',
							onRoutes == 'followProgress' ? theme3 : '',
							onRoutes == 'source' ? theme3 : '',
							onRoutes == 'release' ? theme3 : '',
							onRoutes == 'tag' ? theme3 : ''
						]"
					>
						CRM管理
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="customer" :class="onRoutes == 'customer' ? theme6 : ''">我的客户</el-menu-item>
					<el-menu-item index="CRMHigh" :class="onRoutes == 'CRMHigh' ? theme6 : ''">公海客户</el-menu-item>
					<el-menu-item index="customTag" :class="onRoutes == 'customTag' ? theme6 : ''">字段配置</el-menu-item>
					<el-menu-item index="followProgress" :class="onRoutes == 'followProgress' ? theme6 : ''">跟进阶段配置</el-menu-item>
					<el-menu-item index="source" :class="onRoutes == 'source' ? theme6 : ''">来源配置</el-menu-item>
					<el-menu-item index="release" :class="onRoutes == 'release' ? theme6 : ''">释放管理</el-menu-item>
					<el-menu-item index="tag" :class="onRoutes == 'tag' ? theme6 : ''">标签管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="clue">
				<template v-slot:title="title">
					<i class="el-icon-data-line" :class="[onRoutes == 'clue' ? theme3 : '', onRoutes == 'assignLeads' ? theme3 : '', onRoutes == 'cluePool' ? theme3 : '']"></i>
					<span :class="[onRoutes == 'clue' ? theme3 : '', onRoutes == 'assignLeads' ? theme3 : '', onRoutes == 'cluePool' ? theme3 : '']">线索管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="clue" :class="onRoutes == 'clue' ? theme6 : ''">线索列表</el-menu-item>
					<el-menu-item index="assignLeads" :class="onRoutes == 'assignLeads' ? theme6 : ''">线索回收</el-menu-item>
					<el-menu-item index="cluePool" :class="onRoutes == 'cluePool' ? theme6 : ''">线索池</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="company">
				<template v-slot:title="title">
					<i class="el-icon-lx-cascades" :class="[onRoutes == 'company' ? theme3 : '', onRoutes == 'department' ? theme3 : '', onRoutes == 'user' ? theme3 : '']"></i>
					<span :class="[onRoutes == 'company' ? theme3 : '', onRoutes == 'department' ? theme3 : '', onRoutes == 'user' ? theme3 : '']">企业管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="company" :class="onRoutes == 'company' ? theme6 : ''">企业列表</el-menu-item>
					<el-menu-item index="department" :class="onRoutes == 'department' ? theme6 : ''">部门管理</el-menu-item>
					<el-menu-item index="user" :class="onRoutes == 'user' ? theme6 : ''">员工管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="record">
				<template v-slot:title="title">
					<i class="el-icon-phone-outline" :class="[onRoutes == 'record' ? theme3 : '', onRoutes == 'recording' ? theme3 : '']"></i>
					<span :class="[onRoutes == 'record' ? theme3 : '', onRoutes == 'recording' ? theme3 : '']">话务管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="record" :class="onRoutes == 'record' ? theme6 : ''">通话记录</el-menu-item>
					<el-menu-item index="recording" :class="onRoutes == 'recording' ? theme6 : ''">录音检测</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="userInfo" v-if="webName == '云企通'">
				<template v-slot:title="title">
					<img style="width: 18px;margin-right: 10px;" src="../assets/img/dijia.png" alt="" />
					<span
						:class="[
							onRoutes == 'userInfo' ? theme3 : '',
							onRoutes == 'activationList' ? theme3 : '',
							onRoutes == 'DJAbnormalCallList' ? theme3 : '',
							onRoutes == 'accountReview' ? theme3 : ''
						]"
					>
						迪加管理
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="userInfo" :class="onRoutes == 'userInfo' ? theme6 : ''">用户查询</el-menu-item>
					<el-menu-item index="activationList" :class="onRoutes == 'activationList' ? theme6 : ''">限制返档</el-menu-item>
					<el-menu-item index="DJAbnormalCallList" :class="onRoutes == 'DJAbnormalCallList' ? theme6 : ''">迪加异常话单</el-menu-item>
					<el-menu-item index="accountReview" v-if="" :class="onRoutes == 'accountReview' ? theme6 : ''">开户审核查询</el-menu-item>
				</el-menu-item-group>
			</el-submenu>

			<el-submenu index="addReturns" v-if="webName == '云企通'">
				<template v-slot:title="title">
					<img style="width: 18px;margin-right: 10px;" src="../assets/img/chongzhi.png" alt="" />
					<span :class="onRoutes == 'addReturns' ? theme3 : ''">充值管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="addReturns" :class="onRoutes == 'addReturns' ? theme6 : ''">蜗牛充值</el-menu-item></el-menu-item-group>
			</el-submenu>

			<el-submenu index="report">
				<template v-slot:title="title">
					<i
						class="el-icon-document-copy"
						:class="[onRoutes == 'report' ? theme3 : '', onRoutes == 'companyStatistics' ? theme3 : '', onRoutes == 'seating' ? theme3 : '']"
					></i>
					<span :class="[onRoutes == 'report' ? theme3 : '', onRoutes == 'companyStatistics' ? theme3 : '', onRoutes == 'seating' ? theme3 : '']">统计管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="report" :class="onRoutes == 'report' ? theme6 : ''">员工统计报表</el-menu-item>
					<el-menu-item index="companyStatistics" :class="onRoutes == 'companyStatistics' ? theme6 : ''">公司统计报表</el-menu-item>
					<el-menu-item index="seating" :class="onRoutes == 'seating' ? theme6 : ''">坐席数据</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-menu-item index="termOfValidity" :class="onRoutes == 'termOfValidity' ? theme3 : ''">
				<i class="el-icon-time"></i>
				<span>有效期管理</span>
			</el-menu-item>

			<el-submenu index="cardType">
				<template v-slot:title="title">
					<i
						class="el-icon-s-tools"
						:class="[
							onRoutes == 'cardType' ? theme3 : '',
							onRoutes == 'blackListDatabase' ? theme3 : '',
							onRoutes == 'userSerial' ? theme3 : '',
							onRoutes == 'role' ? theme3 : '',
							onRoutes == 'operationLog' ? theme3 : '',
							onRoutes == 'outboundCallRecords' ? theme3 : '',
							onRoutes == 'setColor' ? theme3 : ''
						]"
					></i>
					<span
						:class="[
							onRoutes == 'cardType' ? theme3 : '',
							onRoutes == 'blackListDatabase' ? theme3 : '',
							onRoutes == 'userSerial' ? theme3 : '',
							onRoutes == 'role' ? theme3 : '',
							onRoutes == 'operationLog' ? theme3 : '',
							onRoutes == 'outboundCallRecords' ? theme3 : '',
							onRoutes == 'setColor' ? theme3 : ''
						]"
					>
						系统设置
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="cardType" :class="onRoutes == 'cardType' ? theme6 : ''">品牌管理</el-menu-item>
					<!-- //在领尚注释!-->
					<el-menu-item index="blackListDatabase" :class="onRoutes == 'blackListDatabase' ? theme6 : ''">黑名单库</el-menu-item>
					<el-menu-item index="userSerial" :class="onRoutes == 'userSerial' ? theme6 : ''">员工分配记录</el-menu-item>
					<el-menu-item index="role" :class="onRoutes == 'role' ? theme6 : ''">角色管理</el-menu-item>
					<!-- //在领尚注释!-->
					<el-menu-item index="operationLog" :class="onRoutes == 'operationLog' ? theme6 : ''">员工操作日志</el-menu-item>
					<el-menu-item index="outboundCallRecords" :class="onRoutes == 'outboundCallRecords' ? theme6 : ''">外呼记录</el-menu-item>
					<el-menu-item index="setColor" :class="onRoutes == 'setColor' ? theme6 : ''">设置主题</el-menu-item>
				</el-menu-item-group>
			</el-submenu>

			<el-submenu index="notice">
				<template v-slot:title="title">
					<i class="el-icon-chat-dot-round" :class="[onRoutes == 'notice' ? theme3 : '', onRoutes == 'addNotice' ? theme3 : '']"></i>
					<span :class="[onRoutes == 'notice' ? theme3 : '', onRoutes == 'addNotice' ? theme3 : '']">公告管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="notice" :class="onRoutes == 'notice' ? theme6 : ''">公告列表</el-menu-item>
					<el-menu-item index="addNotice" :class="onRoutes == 'addNotice' ? theme6 : ''">发布公告</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="OEMset">
				<template v-slot:title="title">
					<i class="el-icon-document-copy" :class="[onRoutes == 'OEMset' ? theme3 : '', onRoutes == 'OEMswiper' ? theme3 : '', onRoutes == 'swipes' ? theme3 : '']"></i>
					<span :class="[onRoutes == 'OEMset' ? theme3 : '', onRoutes == 'OEMswiper' ? theme3 : '', onRoutes == 'swipes' ? theme3 : '']">OEM管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="OEMset" :class="onRoutes == 'OEMset' ? theme6 : ''">OEM设置</el-menu-item>
					<el-menu-item index="OEMswiper" :class="onRoutes == 'OEMswiper' ? theme6 : ''">登录轮播图</el-menu-item>
					<el-menu-item index="swipes" :class="onRoutes == 'swipes' ? theme6 : ''">APP轮播图</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-menu-item index="blackList" :class="onRoutes == 'blackList' ? theme3 : ''">
				<i class="el-icon-s-release"></i>
				<span>黑名单</span>
			</el-menu-item>
			<el-menu-item index="validate" :class="onRoutes == 'validate' ? theme3 : ''">
				<i class="el-icon-finished"></i>
				<span>号码验证</span>
			</el-menu-item>
			<el-menu-item index="afterSales" :class="onRoutes == 'afterSales' ? theme3 : ''">
				<i class="el-icon-warning-outline"></i>
				<span>售后信息</span>
			</el-menu-item>
			<el-menu-item index="operationManual" v-if="webName == '云企通'" :class="onRoutes == 'operationManual' ? theme3 : ''">
				<i class="el-icon-notebook-1"></i>
				<span>使用手册</span>
			</el-menu-item>
			<div class="login_info" v-if="collapse == false">
				<div>最后登录时间:{{ lastLoginAt }}</div>
			</div>
		</el-menu>
		<el-menu
			class="sidebar-el-menu"
			:default-active="onRoutes"
			:collapse="collapse"
			background-color="#ffffff"
			text-color="#000"
			active-text-color="#ffffff"
			unique-opened
			router
			v-else-if="role == 'company'"
		>
			<el-menu-item index="dashboard" :class="onRoutes == 'dashboard' ? theme3 : ''">
				<i class="el-icon-lx-home"></i>
				<span>系统首页</span>
			</el-menu-item>
			<el-submenu index="advancedSearch">
				<template v-slot:title="title">
					<i
						class="el-icon-folder-opened"
						:class="[
							onRoutes == 'advancedSearch' ? theme3 : '',
							onRoutes == 'companySearch' ? theme3 : '',
							onRoutes == 'shopSearch' ? theme3 : '',
							onRoutes == 'industryTemplate' ? theme3 : '',
							onRoutes == 'mapSearch' ? theme3 : '',
							onRoutes == 'batchCheckCompany' ? theme3 : '',
							onRoutes == 'poolAccount' ? theme3 : '',
							onRoutes == 'highSeas' ? theme3 : ''
						]"
					></i>
					<span
						:class="[
							onRoutes == 'advancedSearch' ? theme3 : '',
							onRoutes == 'companySearch' ? theme3 : '',
							onRoutes == 'shopSearch' ? theme3 : '',
							onRoutes == 'industryTemplate' ? theme3 : '',
							onRoutes == 'mapSearch' ? theme3 : '',
							onRoutes == 'batchCheckCompany' ? theme3 : '',
							onRoutes == 'poolAccount' ? theme3 : '',
							onRoutes == 'highSeas' ? theme3 : ''
						]"
					>
						寻迹通
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="advancedSearch" :class="onRoutes == 'advancedSearch' ? theme6 : ''">找线索</el-menu-item>
					<el-menu-item index="companySearch" :class="onRoutes == 'companySearch' ? theme6 : ''">找企业</el-menu-item>
					<el-menu-item index="shopSearch" :class="onRoutes == 'shopSearch' ? theme6 : ''">找店铺</el-menu-item>
					<el-menu-item index="industryTemplate" :class="onRoutes == 'industryTemplate' ? theme6 : ''">找行业</el-menu-item>
					<el-menu-item index="mapSearch" :class="onRoutes == 'mapSearch' ? theme6 : ''">地图找客</el-menu-item>
					<el-menu-item index="batchCheckCompany" :class="onRoutes == 'batchCheckCompany' ? theme6 : ''">批量查询</el-menu-item>
					<el-menu-item index="poolAccount" :class="onRoutes == 'poolAccount' ? theme6 : ''">账号管理</el-menu-item>
					<el-menu-item-group><el-menu-item index="highSeas" :class="onRoutes == 'highSeas' ? theme6 : ''">数据池</el-menu-item></el-menu-item-group>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="packageManagement">
				<template v-slot:title="title">
					<i class="el-icon-guide" :class="[onRoutes == 'purchaseList' ? theme3 : '', onRoutes == 'consultationDetailed' ? theme3 : '']"></i>
					<span :class="[onRoutes == 'purchaseList' ? theme3 : '', onRoutes == 'consultationDetailed' ? theme3 : '']">AXB</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="purchaseList" :class="onRoutes == 'purchaseList' ? theme6 : ''">已购号码</el-menu-item>
					<el-menu-item index="consultationDetailed" :class="onRoutes == 'consultationDetailed' ? theme6 : ''">消费明细</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="customer">
				<template v-slot:title="title">
					<i
						class="el-icon-user"
						:class="[
							onRoutes == 'customer' ? theme3 : '',
							onRoutes == 'CRMHigh' ? theme3 : '',
							onRoutes == 'customTag' ? theme3 : '',
							onRoutes == 'followProgress' ? theme3 : '',
							onRoutes == 'source' ? theme3 : '',
							onRoutes == 'release' ? theme3 : '',
							onRoutes == 'tag' ? theme3 : ''
						]"
					></i>
					<span
						:class="[
							onRoutes == 'customer' ? theme3 : '',
							onRoutes == 'customTag' ? theme3 : '',
							onRoutes == 'CRMHigh' ? theme3 : '',
							onRoutes == 'followProgress' ? theme3 : '',
							onRoutes == 'source' ? theme3 : '',
							onRoutes == 'release' ? theme3 : '',
							onRoutes == 'tag' ? theme3 : ''
						]"
					>
						CRM管理
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="customer" :class="onRoutes == 'customer' ? theme6 : ''">我的客户</el-menu-item>
					<el-menu-item index="CRMHigh" :class="onRoutes == 'CRMHigh' ? theme6 : ''">公海客户</el-menu-item>
					<el-menu-item index="customTag" :class="onRoutes == 'customTag' ? theme6 : ''">字段配置</el-menu-item>
					<el-menu-item index="followProgress" :class="onRoutes == 'followProgress' ? theme6 : ''">跟进阶段配置</el-menu-item>
					<el-menu-item index="source" :class="onRoutes == 'source' ? theme6 : ''">来源配置</el-menu-item>
					<el-menu-item index="release" :class="onRoutes == 'release' ? theme6 : ''">释放管理</el-menu-item>
					<el-menu-item index="tag" :class="onRoutes == 'tag' ? theme6 : ''">标签管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="clue">
				<template v-slot:title="title">
					<i class="el-icon-data-line" :class="[onRoutes == 'clue' ? theme3 : '', onRoutes == 'assignLeads' ? theme3 : '', onRoutes == 'cluePool' ? theme3 : '']"></i>
					<span :class="[onRoutes == 'clue' ? theme3 : '', onRoutes == 'assignLeads' ? theme3 : '', onRoutes == 'cluePool' ? theme3 : '']">线索管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="clue" :class="onRoutes == 'clue' ? theme6 : ''">线索列表</el-menu-item>
					<el-menu-item index="assignLeads" :class="onRoutes == 'assignLeads' ? theme6 : ''">线索回收</el-menu-item>
					<el-menu-item index="cluePool" :class="onRoutes == 'cluePool' ? theme6 : ''">线索池</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="company">
				<template v-slot:title="title">
					<i class="el-icon-lx-cascades" :class="[onRoutes == 'company' ? theme3 : '', onRoutes == 'department' ? theme3 : '', onRoutes == 'user' ? theme3 : '']"></i>
					<span :class="[onRoutes == 'company' ? theme3 : '', onRoutes == 'department' ? theme3 : '', onRoutes == 'user' ? theme3 : '']">企业管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="company" :class="onRoutes == 'company' ? theme6 : ''">企业列表</el-menu-item>
					<el-menu-item index="department" :class="onRoutes == 'department' ? theme6 : ''">部门管理</el-menu-item>
					<el-menu-item index="user" :class="onRoutes == 'user' ? theme6 : ''">员工管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="record">
				<template v-slot:title="title">
					<i class="el-icon-phone-outline" :class="onRoutes == 'record' ? theme3 : ''"></i>
					<span :class="onRoutes == 'record' ? theme3 : ''">话务管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="record" :class="onRoutes == 'record' ? theme6 : ''">通话记录</el-menu-item></el-menu-item-group>
			</el-submenu>
			<el-submenu index="addReturns" v-if="webName == '云企通'" :class="onRoutes == 'addReturns' ? theme3 : ''">
				<template v-slot:title="title">
					<img style="width: 18px;margin-right: 10px;" src="../assets/img/chongzhi.png" alt="" />
					<span :class="onRoutes == 'addReturns' ? theme3 : ''">充值管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="addReturns" :class="onRoutes == 'addReturns' ? theme6 : ''">蜗牛充值</el-menu-item></el-menu-item-group>
			</el-submenu>
			<el-submenu index="report">
				<template v-slot:title="title">
					<i class="el-icon-document-copy" :class="onRoutes == 'report' ? theme3 : ''"></i>
					<span :class="onRoutes == 'report' ? theme3 : ''">统计管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="report" :class="onRoutes == 'report' ? theme6 : ''">员工统计报表</el-menu-item></el-menu-item-group>
			</el-submenu>
			<el-menu-item index="accountReview" :class="onRoutes == 'accountReview' ? theme6 : ''">
				<img style="width: 18px;margin-right: 10px;" src="../assets/img/dijia.png" alt="" />
				<span>开户审核查询</span>
			</el-menu-item>
			<el-menu-item index="blackList" :class="onRoutes == 'blackList' ? theme3 : ''">
				<i class="el-icon-s-release"></i>
				<span>黑名单</span>
			</el-menu-item>
			<el-menu-item index="validate" :class="onRoutes == 'validate' ? theme3 : ''">
				<i class="el-icon-finished"></i>
				<span>号码验证</span>
			</el-menu-item>
			<el-menu-item index="operationManual" v-if="webName == '云企通'" :class="onRoutes == 'operationManual' ? theme3 : ''">
				<i class="el-icon-notebook-1"></i>
				<span>使用手册</span>
			</el-menu-item>
			<div class="login_info" v-if="collapse == false">
				<div>最后登录时间:{{ lastLoginAt }}</div>
			</div>
		</el-menu>
		<el-menu
			class="sidebar-el-menu"
			:default-active="onRoutes"
			:collapse="collapse"
			background-color="#ffffff"
			text-color="#000"
			active-text-color="#ffffff"
			unique-opened
			router
			v-else-if="role == 'normal'"
		>
			<el-menu-item index="dashboard" :class="onRoutes == 'dashboard' ? theme3 : ''">
				<i class="el-icon-lx-home"></i>
				<span>系统首页</span>
			</el-menu-item>
			<el-submenu index="advancedSearch">
				<template v-slot:title="title">
					<i
						class="el-icon-folder-opened"
						:class="[
							onRoutes == 'advancedSearch' ? theme3 : '',
							onRoutes == 'companySearch' ? theme3 : '',
							onRoutes == 'shopSearch' ? theme3 : '',
							onRoutes == 'industryTemplate' ? theme3 : '',
							onRoutes == 'mapSearch' ? theme3 : '',
							onRoutes == 'batchCheckCompany' ? theme3 : '',
							onRoutes == 'poolAccount' ? theme3 : '',
							onRoutes == 'highSeas' ? theme3 : ''
						]"
					></i>
					<span
						:class="[
							onRoutes == 'advancedSearch' ? theme3 : '',
							onRoutes == 'companySearch' ? theme3 : '',
							onRoutes == 'shopSearch' ? theme3 : '',
							onRoutes == 'industryTemplate' ? theme3 : '',
							onRoutes == 'mapSearch' ? theme3 : '',
							onRoutes == 'batchCheckCompany' ? theme3 : '',
							onRoutes == 'poolAccount' ? theme3 : '',
							onRoutes == 'highSeas' ? theme3 : ''
						]"
					>
						寻迹通
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="advancedSearch" :class="onRoutes == 'advancedSearch' ? theme6 : ''">找线索</el-menu-item>
					<el-menu-item index="companySearch" :class="onRoutes == 'companySearch' ? theme6 : ''">找企业</el-menu-item>
					<el-menu-item index="shopSearch" :class="onRoutes == 'shopSearch' ? theme6 : ''">找店铺</el-menu-item>
					<el-menu-item index="industryTemplate" :class="onRoutes == 'industryTemplate' ? theme6 : ''">找行业</el-menu-item>
					<el-menu-item index="mapSearch" :class="onRoutes == 'mapSearch' ? theme6 : ''">地图找客</el-menu-item>
					<el-menu-item index="batchCheckCompany" :class="onRoutes == 'batchCheckCompany' ? theme6 : ''">批量查询</el-menu-item>
					<el-menu-item index="poolAccount" :class="onRoutes == 'poolAccount' ? theme6 : ''">账号管理</el-menu-item>
					<el-menu-item-group><el-menu-item index="highSeas" :class="onRoutes == 'highSeas' ? theme6 : ''">数据池</el-menu-item></el-menu-item-group>
				</el-menu-item-group>
			</el-submenu>

			<el-submenu index="customer">
				<template v-slot:title="title">
					<i class="el-icon-user" :class="[onRoutes == 'customer' ? theme3 : '', onRoutes == 'CRMHigh' ? theme3 : '', onRoutes == 'tag' ? theme3 : '']"></i>
					<span :class="[onRoutes == 'customer' ? theme3 : '', onRoutes == 'CRMHigh' ? theme3 : '', onRoutes == 'tag' ? theme3 : '']">CRM管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="customer" :class="onRoutes == 'customer' ? theme6 : ''">我的客户</el-menu-item>
					<el-menu-item index="CRMHigh" :class="onRoutes == 'CRMHigh' ? theme6 : ''">公海客户</el-menu-item>
					<el-menu-item index="tag" :class="onRoutes == 'tag' ? theme6 : ''">标签管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="clue">
				<template v-slot:title="title">
					<i class="el-icon-data-line" :class="onRoutes == 'clue' ? theme3 : ''"></i>
					<span :class="onRoutes == 'clue' ? theme3 : ''">线索管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="clue" :class="onRoutes == 'clue' ? theme6 : ''">线索列表</el-menu-item></el-menu-item-group>
			</el-submenu>
			<el-submenu index="record">
				<template v-slot:title="title">
					<i class="el-icon-phone-outline" :class="onRoutes == 'record' ? theme3 : ''"></i>
					<span :class="onRoutes == 'record' ? theme3 : ''">话务管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="record" :class="onRoutes == 'record' ? theme6 : ''">通话记录</el-menu-item></el-menu-item-group>
			</el-submenu>
			<el-submenu index="addReturns" v-if="webName == '云企通'">
				<template v-slot:title="title">
					<img style="width: 18px;margin-right: 10px;" src="../assets/img/chongzhi.png" alt="" />
					<span :class="onRoutes == 'addReturns' ? theme3 : ''">充值管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="addReturns" :class="onRoutes == 'addReturns' ? theme6 : ''">蜗牛充值</el-menu-item></el-menu-item-group>
			</el-submenu>
			<el-menu-item index="accountReview" :class="onRoutes == 'accountReview' ? theme3 : ''">
				<img style="width: 18px;margin-right: 10px;" src="../assets/img/dijia.png" alt="" />
				<span>开户审核查询</span>
			</el-menu-item>
			<el-menu-item index="blackList" :class="onRoutes == 'blackList' ? theme3 : ''">
				<i class="el-icon-s-release"></i>
				<span>黑名单</span>
			</el-menu-item>
			<el-menu-item index="validate" :class="onRoutes == 'validate' ? theme3 : ''">
				<i class="el-icon-finished"></i>
				<span>号码验证</span>
			</el-menu-item>
			<el-menu-item index="operationManual" v-if="webName == '云企通'" :class="onRoutes == 'operationManual' ? theme3 : ''">
				<i class="el-icon-notebook-1"></i>
				<span>使用手册</span>
			</el-menu-item>
			<div class="login_info" v-if="collapse == false">
				<div>最后登录时间:{{ lastLoginAt }}</div>
			</div>
		</el-menu>
		<el-menu
			class="sidebar-el-menu"
			:default-active="onRoutes"
			:collapse="collapse"
			background-color="#ffffff"
			text-color="#000"
			active-text-color="#ffffff"
			unique-opened
			router
			v-else-if="role == 'department'"
		>
			<el-menu-item index="dashboard" :class="onRoutes == 'dashboard' ? theme3 : ''">
				<i class="el-icon-lx-home"></i>
				<span>系统首页</span>
			</el-menu-item>

			<el-submenu index="advancedSearch">
				<template v-slot:title="title">
					<i
						class="el-icon-folder-opened"
						:class="[
							onRoutes == 'advancedSearch' ? theme3 : '',
							onRoutes == 'companySearch' ? theme3 : '',
							onRoutes == 'shopSearch' ? theme3 : '',
							onRoutes == 'industryTemplate' ? theme3 : '',
							onRoutes == 'mapSearch' ? theme3 : '',
							onRoutes == 'batchCheckCompany' ? theme3 : '',
							onRoutes == 'poolAccount' ? theme3 : '',
							onRoutes == 'highSeas' ? theme3 : ''
						]"
					></i>
					<span
						:class="[
							onRoutes == 'advancedSearch' ? theme3 : '',
							onRoutes == 'companySearch' ? theme3 : '',
							onRoutes == 'shopSearch' ? theme3 : '',
							onRoutes == 'industryTemplate' ? theme3 : '',
							onRoutes == 'mapSearch' ? theme3 : '',
							onRoutes == 'batchCheckCompany' ? theme3 : '',
							onRoutes == 'poolAccount' ? theme3 : '',
							onRoutes == 'highSeas' ? theme3 : ''
						]"
					>
						寻迹通
					</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="advancedSearch" :class="onRoutes == 'advancedSearch' ? theme6 : ''">找线索</el-menu-item>
					<el-menu-item index="companySearch" :class="onRoutes == 'companySearch' ? theme6 : ''">找企业</el-menu-item>
					<el-menu-item index="shopSearch" :class="onRoutes == 'shopSearch' ? theme6 : ''">找店铺</el-menu-item>
					<el-menu-item index="industryTemplate" :class="onRoutes == 'industryTemplate' ? theme6 : ''">找行业</el-menu-item>
					<el-menu-item index="mapSearch" :class="onRoutes == 'mapSearch' ? theme6 : ''">地图找客</el-menu-item>
					<el-menu-item index="batchCheckCompany" :class="onRoutes == 'batchCheckCompany' ? theme6 : ''">批量查询</el-menu-item>
					<el-menu-item index="poolAccount" :class="onRoutes == 'poolAccount' ? theme6 : ''">账号管理</el-menu-item>
					<el-menu-item-group><el-menu-item index="highSeas" :class="onRoutes == 'highSeas' ? theme6 : ''">数据池</el-menu-item></el-menu-item-group>
				</el-menu-item-group>
			</el-submenu>

			<el-submenu index="customer">
				<template v-slot:title="title">
					<i class="el-icon-user" :class="[onRoutes == 'customer' ? theme3 : '', onRoutes == 'CRMHigh' ? theme3 : '']"></i>
					<span :class="[onRoutes == 'customer' ? theme3 : '', onRoutes == 'CRMHigh' ? theme3 : '']">CRM管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="customer" :class="onRoutes == 'customer' ? theme6 : ''">我的客户</el-menu-item>
					<el-menu-item index="CRMHigh" :class="onRoutes == 'CRMHigh' ? theme6 : ''">公海客户</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="clue">
				<template v-slot:title="title">
					<i class="el-icon-data-line" :class="onRoutes == 'clue' ? theme3 : ''"></i>
					<span :class="onRoutes == 'clue' ? theme3 : ''">线索管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="clue" :class="onRoutes == 'clue' ? theme6 : ''">线索列表</el-menu-item>
					<el-menu-item index="assignLeads" :class="onRoutes == 'assignLeads' ? theme6 : ''">线索回收</el-menu-item>
					<el-menu-item index="cluePool" :class="onRoutes == 'cluePool' ? theme6 : ''">线索池</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="department">
				<template v-slot:title="title">
					<i class="el-icon-lx-cascades" :class="onRoutes == 'department' ? theme3 : ''"></i>
					<span :class="onRoutes == 'department' ? theme3 : ''">企业管理</span>
				</template>
				<el-menu-item-group>
					<el-menu-item index="department" :class="onRoutes == 'assignLeads' ? theme6 : ''">部门管理</el-menu-item>
					<el-menu-item index="user" :class="onRoutes == 'assignLeads' ? theme6 : ''">员工管理</el-menu-item>
				</el-menu-item-group>
			</el-submenu>
			<el-submenu index="record">
				<template v-slot:title="title">
					<i class="el-icon-phone-outline" :class="onRoutes == 'record' ? theme3 : ''"></i>
					<span :class="onRoutes == 'record' ? theme3 : ''">话务管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="record" :class="onRoutes == 'record' ? theme6 : ''">通话记录</el-menu-item></el-menu-item-group>
			</el-submenu>
			<el-submenu index="addReturns" v-if="webName == '云企通'">
				<template v-slot:title="title">
					<img style="width: 18px;margin-right: 10px;" src="../assets/img/chongzhi.png" alt="" />
					<span :class="onRoutes == 'addReturns' ? theme3 : ''">充值管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="addReturns" :class="onRoutes == 'addReturns' ? theme6 : ''">蜗牛充值</el-menu-item></el-menu-item-group>
			</el-submenu>
			<el-submenu index="report">
				<template v-slot:title="title">
					<i class="el-icon-document-copy" :class="onRoutes == 'report' ? theme3 : ''"></i>
					<span :class="onRoutes == 'report' ? theme3 : ''">统计管理</span>
				</template>
				<el-menu-item-group><el-menu-item index="report" :class="onRoutes == 'report' ? theme6 : ''">员工统计报表</el-menu-item></el-menu-item-group>
			</el-submenu>
			<el-menu-item index="accountReview" :class="onRoutes == 'accountReview' ? theme3 : ''">
				<img style="width: 18px;margin-right: 10px;" src="../assets/img/dijia.png" alt="" />
				<span>开户审核查询</span>
			</el-menu-item>
			<el-menu-item index="blackList" :class="onRoutes == 'blackList' ? theme3 : ''">
				<i class="el-icon-s-release"></i>
				<span>黑名单</span>
			</el-menu-item>
			<el-menu-item index="validate" :class="onRoutes == 'validate' ? theme3 : ''">
				<i class="el-icon-finished"></i>
				<span>号码验证</span>
			</el-menu-item>
			<el-menu-item index="operationManual" v-if="webName == '云企通'">
				<i class="el-icon-notebook-1"></i>
				<span>使用手册</span>
			</el-menu-item>
			<div class="login_info" v-if="collapse == false">
				<div>最后登录时间:{{ lastLoginAt }}</div>
			</div>
		</el-menu>
		<el-menu
			class="sidebar-el-menu"
			:default-active="onRoutes"
			:collapse="collapse"
			background-color="#ffffff"
			text-color="#000"
			active-text-color="#ffffff"
			unique-opened
			router
			v-else-if="role == 'gm'"
		>
			<el-menu-item index="GM" :class="onRoutes == 'GM' ? theme3 : ''">
				<i class="el-icon-lx-home"></i>
				<span>国美录音</span>
			</el-menu-item>
		</el-menu>
	</div>
</template>

<script>
// import bus from "../common/bus";
import { getOemInfo } from '../api/index.js';
export default {
	data() {
		return {
			lastLoginIp: '',
			lastLoginAt: '',
			role: '',
			title: '',
			webInfo: '',
			webName: '',
			theme: localStorage.getItem('theme'),
			theme2: localStorage.getItem('theme2'),
			theme3: localStorage.getItem('theme3'),
			theme4: localStorage.getItem('theme4'),
			theme6: localStorage.getItem('theme6'),
			submit: false
		};
	},
	computed: {
		onRoutes() {
			return this.$route.path.replace('/', '');
		},
		collapse() {
			return this.$store.state.collapse;
		}
	},
	created() {
		this.lastLoginIp = localStorage.getItem('lastLoginIp');
		this.lastLoginAt = localStorage.getItem('lastLoginAt');
		this.getOEMagentOem();
		let role = localStorage.getItem('ms_role');
		console.log(role, '我是权限');
		this.role = localStorage.getItem('ms_role');
	},
	methods: {
		getOEMagentOem() {
			// let url = 'crm.jilinlingshang.com';
			let url = window.location.host;
			getOemInfo(url)
				.then(res => {
					if (res.code == 200) {
						this.webInfo = res.data;
						let linkicon = document.querySelector("link[rel*='icon']") || document.createElement('link');
						linkicon.href = res.data.icon;
						localStorage.setItem('webName', res.data.webName);
						this.webName = res.data.webName;
					} else {
						this.$message.error('查询失败，请稍后再试！');
					}
				})
				.catch(err => {
					this.$message.error('查询失败，请稍后再试！');
				});
		},
		// 侧边栏折叠
		collapseChage() {
			this.$store.commit('hadndleCollapse', !this.collapse);
		}
	}
};
</script>

<style scoped>
.collapse-btn {
	float: left;
	padding: 0 21px;
	cursor: pointer;
	line-height: 70px;
}

.logo-img .logo {
	float: left;
	height: 100%;
	width: 216px;
	display: flex;
	flex-direction: row;
	align-items: center;
	color: #6c6cf9;
}
.logo-img .logo .img {
	width: 42px;
	margin: 0 15px 0 20px;
}
.logo-img .logo .text {
	width: 94px;
}

.logo-img {
	box-sizing: border-box;
	width: 216px;
	height: 70px;
	font-size: 22px;
	margin-bottom: 20px;
	color: #666;
}
.sidebar {
	display: block;
	position: absolute;
	width: 216px;
	left: 0;
	top: 0;
	bottom: 0;
	overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
	width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
	width: 216px;
}
.sidebar > ul {
	height: calc(100%-70px);
}
.el-menu--collapse .el-menu-item i {
	position: relative;
	left: -38px;
}
.el-menu-item span {
	font-size: 14px !important;
}
.login_info {
	display: block;
	position: fixed;
	left: 10px;
	bottom: 12px;
}
.login_info div {
	font-size: 12px;
}
</style>
<style>
.el-menu--collapse * {
	text-align: center !important;
}
.el-menu-item .el-menu-item-group__title,
.el-submenu .el-menu-item-group__title {
	padding: 0;
}

.el-menu .el-menu-item,
.el-menu .el-submenu {
	font-size: 14px !important;
	margin: 0 auto !important;
}
.el-menu .el-menu-item.is-active {
	border-radius: 4px;
}

.el-submenu.is-opened .el-menu .el-menu-item:hover {
	border-radius: 4px !important;
}

.el-submenu__title,
.el-menu-item,
.el-submenu .el-menu-item {
	height: 46px !important;
	font-size: 14px !important;
	line-height: 46px !important;
}

.el-submenu__title,
.el-submenu .el-menu-item {
	padding-left: 50px !important;
}
.el-menu-item {
	border-radius: 4px !important;
	width: 93.5% !important;
	min-width: 150px !important;
	border: none !important;
}
.el-submenu__title {
	padding-left: 27px !important;
}
.el-submenu__title span {
	font-size: 14px !important;
}
</style>
